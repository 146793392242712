import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware(() => {
  const { getUserData } = useAuthStore();

  if (!getUserData.profileFilled) {
    return navigateTo('/cabinet/personal-data', { external: true });
  }

  if (getUserData.needDocumentsVerification) {
    return navigateTo('/cabinet/verification', { external: true });
  }

  return navigateTo('/cabinet/balance', { external: true });
});
